<template>
  <div class="patentTransfer">
    <Top></Top>
    <Fix></Fix>
    <div class="patentTransfer-box">
      <!-- 头部 -->
      <div class="patentTransfer-box-top">
        专利转让申请
      </div>
      <div class="fail" v-if="this.$route.query.status == '资料审核不通过'">审核不通过原因：{{remark}}</div>
      <!-- 类型 -->
      <div class="patentTransfer-box-type">
        <span>申请人类型</span>
        <input type="radio" value="1" v-model="find" name="invoice">
        <span>企业</span>
        <input type="radio" value="2" v-model="find" name="invoice">
        <span>个人</span>
      </div>
      <!-- 企业申请人 -->
      <div class="patentTransfer-box-applicant" v-show="divshow">
        <span>申请人：</span>
        <select v-model="chose" @change='getValue1'>
          <option value="0">请选择</option>
          <option  v-for="(v,k) in arr2" v-bind:value="v.id" :key="k">{{v.company_name}}</option>
        </select>
        <span  @click="toconfire1()">添加申请人</span>
        <div class="form-msg">{{choseName}}</div>
        <div class="remarks">
          <img src="/static/images/alert1.png" alt="">
          <span>申请人为受让人</span>
        </div>
      </div>
      <!-- 个人申请人 -->
      <div class="patentTransfer-box-applicant" v-show="!divshow">
        <span>申请人：</span>
        <select v-model="chose1" @click='getValue2'>
          <option value="0">请选择</option>
          <option v-for="(v,k) in arr1" v-bind:value="v.id" :key="k">{{v.chinese_name}}</option>
        </select>
        <span @click="toconfire3()">添加申请人</span>
        <div class="form-msg">{{choseName}}</div>
        <div class="remarks">
          <img src="/static/images/alert1.png" alt="">
          <span>申请人为受让人</span>
        </div>
      </div>
      <!-- 专利申请号： -->
      <div class="patentTransfer-box-one">
        <span>专利申请号：</span>
        <input v-model="chineseName" type="text" maxlength="14">
        <div class="form-msg">{{chineseNameTip}}</div>
      </div>
      <!--  专利名称： -->
      <div class="patentTransfer-box-one">
        <span>专利名称：</span>
        <input v-model="identity"  maxlength="18" type="text">
        <div class="form-msg">{{identityId}}</div>
      </div>
      <!-- 代理机构： -->
      <div class="patentTransfer-box-one">
        <span>代理机构：</span>
        <input v-model="jsmobile" type="text">
        <div class="form-msg">{{jsmobileId}}</div>
      </div>
      <!-- 代理机构解聘书 -->
      <div class="patentTransfer-box-two">
        <span>代理机构解聘书：</span>
        <input type="text" v-model="name1" disabled='disabled' class="mb26">
        <label for="fileNone"></label>
        <input type="file" id="fileNone" @change="update" ref="file" style="display:none;">
        <span>（上传文件格式为pdf、word或压缩包文件）</span>
        <div class="remarks">
          <img src="/static/images/alert1.png" alt="">
          <span>代理机构发生变化需上传</span>
        </div>
      </div>
      <!-- 转让协议/合同原件 -->
      <div class="patentTransfer-box-two">
        <span>转让协议/合同原件：</span>
        <input type="text" v-model="name2" disabled='disabled'>
        <label for="fileNone1"></label>
        <input type="file" id="fileNone1" name="file" @change="updateCAD" style="display:none;">
        <span>（上传文件格式为pdf、word或压缩包文件）</span>
        <div class="form-msg">{{name2Ip}}</div>
        <div class="remarks1">
          <img src="/static/images/alert25.png" alt="">
          <a href="/static/images/专利转让协议(专利局备案版).pdf" target="_blank">查看模板</a>
        </div>
      </div>
       <!-- 申请专利费用减缓： -->
      <div class="patentTransfer-box-type">
        <span>申请专利费用减缓：</span>
        <input type="radio" value="1" v-model="find2" name="invoice1">
        <span>是</span>
        <input type="radio" value="2" v-model="find2" name="invoice1">
        <span>否</span>
        <div>
          <img src="/static/images/alert1.png" alt="">
          <span @mouseenter="enter()" @mouseleave="leave()">专利费用减缓条件</span>
        </div>
        <div class="posab" v-show="posab">
          <p>1.申请人是公司：</p>
          <p>上年度应纳税所得额高于100万不用提供材料，低于100万需提供</p>
          <p>以下材料,判断标准请看上年度企业所得税年度纳税申报表A</p>
          <p>类中的23栏是否高于100万或低于100万。</p>
          <p>2.申请人是个人：</p>
          <p>年收入低于6万元</p>
        </div>
      </div>
      <!--申报表A类 -->
      <div class="patentTransfer-box-declareA" v-show="apper1&&showTf">
        <span>上年度企业所得税年度纳税申报表A类盖公章：</span>
        <div>
          <el-upload
            accept="image/*"
            :http-request="uploadData1"
            :show-file-list="false"
            action="">
            <img class="dataimg" :src="dataimg1?dataimg1:'/static/images/uploadData.png'" alt="">
          </el-upload>
        </div>
        <span>（除上海市以外，其他地区材料请自行面交或邮寄）</span>
        <div class="form-msg">{{inventionsOtherTip}}</div>
      </div>
      <!-- 申报表A类封面： -->
      <div class="patentTransfer-box-declareA" v-show="apper1&&showTf">
        <span>上年度企业所得税年度纳税申报表A类封面盖公章：</span>
        <div>
          <el-upload
            accept="image/*"
            :http-request="uploadData2"
            :show-file-list="false"
            action="">
            <img class="dataimg" :src="dataimg2?dataimg2:'/static/images/uploadData.png'" alt="">
          </el-upload>
        </div>
        <div class="form-msg">{{inventionsOtherTip1}}</div>
      </div>
      <!-- 个人收入 -->
       <div class="patentTransfer-box-declareA" v-show="apper&&showTf">
        <span>个人收入证明盖单位公章：</span>
        <div>
          <el-upload
            accept="image/*"
            :http-request="uploadData3"
            :show-file-list="false"
            action="">
            <img class="dataimg" :src="dataimg3?dataimg3:'/static/images/uploadData.png'" alt="">
          </el-upload>
        </div>
         <span>（除上海市以外，其他地区材料请自行面交或邮寄）</span>
        <div  class="form-msg">{{inventionsOtherTip2}}</div>
      </div>
      <!-- 提交取消 -->
      <div class="patentTransfer-box-delSub">
        <button  @click="back()">取消</button>
        <button @click="addinvoice" :disabled = "isDisabled">提交</button>
      </div>
    </div>
    <Foot></Foot>
  </div>
</template>

<script>
import Top from '../ui/top.vue';
import Foot from '../ui/foot.vue';
import Fix from '../ui/fix.vue';
export default {
  name:'patentTransfer',
  components: {
      Top,
      Foot,
      Fix
    },
    data () {
      return {
        // 表单数据
        find:'1',
        find2:'1',
        // 企业申请人
        arr2:[],
        // 个人申请人
        arr1:[],
        // 申请人
        chose:'0',
        chose1:'0',
        choseName:'',
        // 专利申请号 数据
        chineseName:'',
        chineseNameTip:'',
        // 专利名称 数据
        identity:'',
        identityId:'',
        // 代理机构 数据
        jsmobile:'',
        jsmobileId:'',
        // 代理机构解聘书 传给后台数据
        book1:'',
        // 代理机构解聘书 展示在页面的数据
        name1:'',
        // 转让协议/合同原件 传给后台数据
        book2:'',
        // 转让协议/合同原件 展示在页面的数据
        name2:'',
        name2Ip:'',
        // 申报表A 展示在页面的图片
        dataimg2:'',
        dataimg1:'',
        inventionsOtherTip1:'',
        inventionsOtherTip:'',
        book3:'',
        // 个人 展示在页面的图片
        dataimg3:'',
        inventionsOtherTip2:'',
        // 控制显示隐藏
        apper:false,
        apper1:true,
        divshow:true,
        showTf:true,
        posab:false,
        // 个人和企业id
        buss_id:'',
        isDisabled: false,
        // 审核不通过原因
        remark:''
      }
    },
    methods:{
      // 弹框
      tip(type,msg){
        return this.$message({
            type:type,
            message:msg,
            showClose:true,
            duration:1500
        })
      },
      // 代理机构解聘书
      update(e) {
        let file = e.target.files[0]
        let formData = new FormData();
        formData.append('url_img',file);
        if(formData.get('url_img') !=  "undefined" ) {
          this.name1 = formData.get('url_img').name
          localStorage.setItem('name7',this.name1)
          let config = {
            headers:{'Content-Type':'multipart/form-data'}
          };
          this.$http.post(this.GLOBAL.base_url+'/api/BrandUpload/brandUploadFile',formData).then( res => {
            if(res.data.code == '1') {
              this.book1 = res.data.filepath
            }
          })
        }
      },
      // 转让协议/合同原件
      updateCAD(e) {
        let file = e.target.files[0]
        let formData = new FormData();
        formData.append('url_img',file);
        if(formData.get('url_img') !=  "undefined" ) {
          this.name2 = formData.get('url_img').name
          localStorage.setItem('name8',this.name2)
          let config = {
            headers:{'Content-Type':'multipart/form-data'}
          };
          this.$http.post(this.GLOBAL.base_url+'/api/BrandUpload/brandUploadFile',formData).then( res => {
            if(res.data.code == '1') {
              this.book2 = res.data.filepath
            }
          })
        }
      },
      // 申报表A类
      uploadData1(img){
            let formData=new FormData();
            formData.append('url_img',img.file);
            // console.log(formData)
            this.$http.post(this.GLOBAL.base_url+'/api/BrandUpload/brandUploadFile',formData).then(res=>{
              if(res.data.code == "1"){
                this.ser_img1 = res.data.filepath;
                this.dataimg1 = this.GLOBAL.base_url+ res.data.filepath;
              }
            });
      },
      // 申报表A类封面
       uploadData2(img){
            let formData=new FormData();
            formData.append('url_img',img.file);
            this.$http.post(this.GLOBAL.base_url+'/api/BrandUpload/brandUploadFile',formData).then(res=>{
              if(res.data.code == "1"){
                this.ser_img2 = res.data.filepath;
                this.dataimg2 = this.GLOBAL.base_url+ res.data.filepath;
              }
            });
      },
      // 个人
       uploadData3(img){
            let formData=new FormData();
            formData.append('url_img',img.file);
            this.$http.post(this.GLOBAL.base_url+'/api/BrandUpload/brandUploadFile',formData).then(res=>{
              if(res.data.code == "1"){
                this.ser_img3 = res.data.filepath;
                this.dataimg3 = this.GLOBAL.base_url+ res.data.filepath;
              }
            });
      },
      // 添加申请企业
      toconfire1() {
        let routeData = this.$router.resolve({ path: '/personal/companyForm'});
        window.open(routeData.href, '_blank');
      },
      // 添加申请人
      toconfire3(){
        let routeData = this.$router.resolve({ path: '/personal/personalForm'});
        window.open(routeData.href, '_blank');
      },
      // 获取认证信息
      getData1(){
        this.$http.post(this.GLOBAL.base_url+'/api/BrandUpload/applyList',{
          user_id:this.$cookie.getCookie('u_id')
        }).then(res=>{
            // console.log(res)
            if(res.data.code == "1"){
              this.arr1 = res.data.Authentication;
              this.arr2 = res.data.business;
              this.type = res.data.one_cate;
            }
          })
      },
      // 获取 个人和企业id
      getValue1() {
        if (this.find == '1') {
          this.buss_id = this.chose;
        }
      },
      getValue2() {
        if (this.find == '2') {
          this.buss_id = this.chose1;
          // console.log(this.buss_id)
          this.getData1()
        }
      },
      // 提交
      addinvoice(){
        this.isDisabled = true
        setTimeout(() => {
          this.isDisabled = false
        },2000)
        if(this.find == '1'&& this.find2 == '1') {
          this.book3 = this.ser_img2 + ',' + this.ser_img1
          if(this.chose == '0') {
            this.choseName = '请选择申请人'
          }else if(this.chineseName == '') {
            this.chineseNameTip = '请输入专利申请号'
          }else if(this.identity == '') {
            this.identityId = '请输入专利名称'
          }else if (this.name2 == '') {
              this.name2Ip = '请上传转让协议/合同原件'
          }else if(this.dataimg1 == '') {
                this.inventionsOtherTip = '请上传上年度企业所得税年度纳税申报表A类盖公章'
          }else if (this.dataimg2 == '') {
                this.inventionsOtherTip1='请上传上年度企业所 得税年度纳税申报表A类封面盖公章'
          }else {
            this.$http.post(this.GLOBAL.base_url+'/api/patent/changeTransfer',{
              user_id:this.$cookie.getCookie('u_id'),
              id:this.$route.query.id,
              type:this.find,
              buss_id:this.buss_id,
              apply_no:this.chineseName,
              name:this.identity,
              book1:this.book1,
              book2:this.book2,
              agent:this.jsmobile,
              book3:this.book3,
              is_reduce:this.find2,
            }).then( res => {
              if(res.data.code == "1"){
                  this.tip('success','提交成功')
                  setTimeout(()=>{
                     this.$router.push('/personal/orderPatent?status=all')
                  },500)
              }else{
                 this.tip('error',res.data.msg)
              }
            })
          }
        }
        if(this.find == '1'&& this.find2 == '2') {
          if(this.chose == '0') {
            this.choseName = '请选择申请人'
          }else if(this.chineseName == '') {
            this.chineseNameTip = '请输入专利申请号'
          }else if(this.identity == '') {
            this.identityId = '请输入专利名称'
          }else if (this.name2 == '') {
              this.name2Ip = '请上传转让协议/合同原件'
          }else {
            this.$http.post(this.GLOBAL.base_url+'/api/patent/changeTransfer',{
              user_id:this.$cookie.getCookie('u_id'),
              id:this.$route.query.id,
              type:this.find,
              buss_id:this.buss_id,
              apply_no:this.chineseName,
              name:this.identity,
              book1:this.book1,
              book2:this.book2,
              agent:this.jsmobile,
              is_reduce:this.find2,
            }).then( res => {
              if(res.data.code == "1"){
                  this.tip('success','提交成功')
                  setTimeout(()=>{
                     this.$router.push('/personal/orderPatent?status=all')
                  },500)
              }else{
                 this.tip('error',res.data.msg)
              }
            })
          }
        }
        if(this.find == '2'&&this.find2 == '1') {
          this.book3 = this.ser_img3
          if(this.chose1 == '0') {
            this.choseName = '请选择申请人'
          }else if(this.chineseName == '') {
            this.chineseNameTip = '请输入专利申请号'
          }else if(this.identity == '') {
            this.identityId = '请输入专利名称'
          }else if (this.name2 == '') {
              this.name2Ip = '请上传转让协议/合同原件'
          }else if(this.dataimg3 == '') {
                this.inventionsOtherTip2 = '请上传个人收入证明盖单位公章'
          }else {
            this.$http.post(this.GLOBAL.base_url+'/api/patent/changeTransfer',{
              user_id:this.$cookie.getCookie('u_id'),
              id:this.$route.query.id,
              type:this.find,
              buss_id:this.buss_id,
              apply_no:this.chineseName,
              name:this.identity,
              book1:this.book1,
              book2:this.book2,
              agent:this.jsmobile,
              book3:this.book3,
              is_reduce:this.find2
            }).then( res => {
              if(res.data.code == "1"){
                  this.tip('success','提交成功')
                  setTimeout(()=>{
                     this.$router.push('/personal/orderPatent?status=all')
                  },500)
              }else{
                 this.tip('error',res.data.msg)
              }
            })
          }
        }
        if(this.find == '2'&&this.find2 == '2') {
          if(this.chose1 == '0') {
            this.choseName = '请选择申请人'
          }else if(this.chineseName == '') {
            this.chineseNameTip = '请输入专利申请号'
          }else if(this.identity == '') {
            this.identityId = '请输入专利名称'
          }else if (this.name2 == '') {
              this.name2Ip = '请上传转让协议/合同原件'
          }else {
            this.$http.post(this.GLOBAL.base_url+'/api/patent/changeTransfer',{
              user_id:this.$cookie.getCookie('u_id'),
              id:this.$route.query.id,
              type:this.find,
              buss_id:this.buss_id,
              apply_no:this.chineseName,
              name:this.identity,
              book1:this.book1,
              book2:this.book2,
              agent:this.jsmobile,
              is_reduce:this.find2
            }).then( res => {
              if(res.data.code == "1"){
                  this.tip('success','提交成功')
                  setTimeout(()=>{
                     this.$router.push('/personal/orderPatent?status=all')
                  },500)
              }else{
                 this.tip('error',res.data.msg)
              }
            })
          }
        }
      },
      // 修改资料
      getData2(){
        this.$http.post(this.GLOBAL.base_url+'/api/patent/changeTransferShow',{
          user_id:this.$cookie.getCookie('u_id'),
          id:this.$route.query.id,
          type:this.$route.query.click_type
        }).then( res => {
          // console.log(res)
          if(res.data.code == '1') {
            this.remark = res.data.data.remark
            if(res.data.data.type == '1' && res.data.data.is_reduce == '1'){
              this.find = res.data.data.type
              this.chose = res.data.data.buss_id
              this.buss_id = res.data.data.buss_id
              this.chineseName = res.data.data.apply_no
              this.identity = res.data.data.name
              this.jsmobile = res.data.data.agent
              this.book1 = res.data.data.book1
              this.book2 = res.data.data.book2
              // this.name1 = res.data.data.book1
              // this.name2 = res.data.data.book2
              this.find2 = res.data.data.is_reduce
              this.dataimg1 =this.GLOBAL.base_url+ res.data.data.book3.split(',')[0]
              this.dataimg2 =this.GLOBAL.base_url+ res.data.data.book3.split(',')[1]
              this.ser_img1 = res.data.data.book3.split(',')[0]
              this.ser_img2 = res.data.data.book3.split(',')[0]
            }
            if(res.data.data.type == '1' && res.data.data.is_reduce == '2'){
              this.find = res.data.data.type
              this.chose = res.data.data.buss_id
              this.buss_id = res.data.data.buss_id
              this.chineseName = res.data.data.apply_no
              this.identity = res.data.data.name
              this.jsmobile = res.data.data.agent
              this.book1 = res.data.data.book1
              this.book2 = res.data.data.book2
              // this.name1 = res.data.data.book1
              // this.name2 = res.data.data.book2
              this.find2 = res.data.data.is_reduce
            }
            if(res.data.data.type == '2' && res.data.data.is_reduce == '1'){
              this.find = res.data.data.type
              this.chose1 = res.data.data.buss_id
              this.buss_id = res.data.data.buss_id
              this.chineseName = res.data.data.apply_no
              this.identity = res.data.data.name
              this.jsmobile = res.data.data.agent
              this.book1 = res.data.data.book1
              this.book2 = res.data.data.book2
              // this.name1 = res.data.data.book1
              // this.name2 = res.data.data.book2
              this.find2 = res.data.data.is_reduce
              this.dataimg3 = this.GLOBAL.base_url+res.data.data.book3
              this.ser_img3 = res.data.data.book3
            }
            if(res.data.data.type == '2' && res.data.data.is_reduce == '2'){
              this.find = res.data.data.type
              this.chose1 = res.data.data.buss_id
              this.buss_id = res.data.data.buss_id
              this.chineseName = res.data.data.apply_no
              this.identity = res.data.data.name
              this.jsmobile = res.data.data.agent
              this.book1 = res.data.data.book1
              this.book2 = res.data.data.book2
              // this.name1 = res.data.data.book1
              // this.name2 = res.data.data.book2
              this.find2 = res.data.data.is_reduce
            }
          }
        })
      },
      // 取消
      back(){
        this.$router.back()
      },
      // 减缓说明
      enter(){
        this.posab = true;
      },
      leave(){
        this.posab = false
      },
    },
    created(){
      this.getData1()
      if(this.$route.query.edit == "2") {
        this.getData2()
        this.name1 = localStorage.getItem('name7')
        this.name2 = localStorage.getItem('name8')
      }
    },
    watch:{
      // 申请企业
      'chose'(newValue) {
        if(newValue == '0') {
          return this.choseName = '请选择申请人'
        }
        this.choseName = ''
      },
      // 申请人
      'chose1'(newValue) {
        if(newValue == '0') {
          return this.choseName = '请选择申请人'
        }
        this.choseName = ''
      },
       // 专利申请号
      'chineseName'(newValue){
        if(newValue == ""){
          return this.chineseNameTip='请输入专利申请号';
        }
        this.chineseNameTip= ""
      },
       // 专利名称
      'identity'(newValue) {
        if (newValue == '') {
          return this.identityId = '请输入专利名称'
        }
        return this.identityId = ''
      },
      //代理机构
      // 'jsmobile'(newValue){
      //   if(newValue == ""){
      //     return this.jsmobileId='请输入代理机构';
      //   }
      //   this.jsmobileId= ""
      // },
      // 控制个人的显示隐藏
      'find'(newValue){
        if(newValue == "1"){
                this.apper = false
                this.apper1 = true
                this.divshow = true

        }
        if(newValue == "2"){
            this.apper = true
            this.apper1 = false
            this.divshow = false

        }
      },
      // 转让协议/合同原件
      'name2'(newValue) {
        if(newValue == '') {
          return this.name2Ip = '请上传转让协议/合同原件';
        }
        this.name2Ip = ''
      },
      // 控制减缓材料显示隐藏
      'find2'(newValue) {
        if(newValue == '1') {
          this.showTf = true
        }
        if (newValue == '2') {
          this.showTf = false
        }
      },
       // A类
      'dataimg1'(newValue){
          if(newValue == ""){
              return this.inventionsOtherTip='请上传上年度企业所得税年度纳税申报表A类盖公章';
          }
          this.inventionsOtherTip= ""
      },
      // A类封面
      'dataimg2'(newValue){
          if(newValue == ""){
              return this.inventionsOtherTip1='请上传上年度企业所得税年度纳税申报表A类封面盖公章';
          }
          this.inventionsOtherTip1= ""
      },
      'dataimg3'(newValue){
          if(newValue == ""){
              return this.inventionsOtherTip2='请上传个人收入证明盖单位公章';
          }
          this.inventionsOtherTip1= ""
      },
    }
}
</script>

<style scoped>
/* 公共样式 */
 input {
    width: 744px;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    box-sizing: border-box;
    border: solid 1px #d6d6d6;
    font-family: "PingFangSC";
    font-size: 16px;
    color: #999999;
    border-radius: 4px;
  }

  .patentTransfer-box {
    width: 980px;
    margin: 0 auto;
    /* height: 2000px; */
    padding-top: 48px;
    box-sizing: border-box;
  }
  /* 头部 */
  .patentTransfer-box-top {
    width: 980px;
    height: 60px;
    line-height: 60px;
    font-family: "PingFangSC";
    font-size: 20px;
    color: #cdcdcd;
    border-bottom: 1px solid #d8d8d8;
    margin-bottom: 26px;
  }
  /* 类型&&申请专利费用减缓 */
  .patentTransfer-box-type {
    /* margin-top: 26px; */
    margin-bottom:26px;
    position: relative;
  }
  .patentTransfer-box-type > span:nth-child(1) {
    display: block;
    margin-bottom: 17px;
    font-family: "PingFangSC";
    font-size: 18px;
    color: #333;
  }
  .patentTransfer-box-type > input {
    width: 16px;
    height: 16px;
    cursor: pointer;
    vertical-align: middle;
    margin-right: 10px;
  }
  .patentTransfer-box-type > span:nth-child(3) {
    /* display: block; */
    /* margin-bottom: 17px; */
    font-family: "PingFangSC";
    font-size: 14px;
    color: #333;
    margin-right: 66px;
    font-weight: 300;
  }
   .patentTransfer-box-type > span:nth-child(5) {
    font-family: "PingFangSC";
    font-size: 14px;
    color: #333;
    font-weight: 300;
  }
  .patentTransfer-box-type > div {
    font-family: "PingFangSC";
    font-size: 14px;
    color: #EB5E00;
    font-weight: 300;
    float: right;
    /* border-bottom: 1px solid #EB5E00; */
  }
  .patentTransfer-box-type > div > img {
    /* margin-right: 6px; */
    vertical-align: middle;
  }
  .patentTransfer-box-type > div > span {
    vertical-align: middle;
    border-bottom: 1px solid #EB5E00;
  }
  /* 专利申请人 */
  .patentTransfer-box-applicant>span:nth-child(1) {
    font-family: "PingFangSC";
    font-size: 18px;
    color: #333;
    display: block;
    margin-bottom: 16px;
  }
  .patentTransfer-box-applicant > select {
    width: 744px;
    height: 40px;
    line-height: 40px;
    padding-left: 8px;
    box-sizing: border-box;
    border: solid 1px #d6d6d6;
    font-family: "PingFangSC";
    font-size: 16px;
    color: #999999;
  }
  .patentTransfer-box-applicant>span:nth-child(3) {
    font-family: "PingFangSC";
    font-size: 16px;
    color: #EB5E00;
    margin: 0px 24px 0 10px;
    cursor: pointer;
  }
  .patentTransfer-box-applicant>span:nth-child(4) {
    font-family: "PingFangSC";
    font-size: 16px;
    color: #4a90e2;
    cursor: pointer;
  }
  /* 专利申请号&&专利名称&&代理机构*/
  .patentTransfer-box-one>span:nth-child(1) {
    font-family: "PingFangSC";
    font-size: 18px;
    color: #333;
    display: block;
    margin-bottom: 16px;
  }
  .patentTransfer-box-one>span:nth-child(3) {
    font-family:" PingFangSC";
    font-size: 16px;
    font-weight: 300;
    color: #EB5E00;
  }
  /* 代理机构解聘书：&&转让协议/合同原件 */
  .patentTransfer-box-two {
    position: relative;
    width: 744px;
  }
  .patentTransfer-box-two >span:nth-child(1) {
    font-family: "PingFangSC";
    font-size: 18px;
    color: #333;
    display: block;
    margin-bottom: 16px;
  }
  .patentTransfer-box-two > div:nth-child(2) {
    width: 744px;
    height: 40px;
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    border-right: none;
    line-height: 40px;
    padding-left: 8px;
    box-sizing: border-box;
  }
  .patentTransfer-box-two > label {
    width: 88px;
    height: 40px;
    display: block;
    /* border: 1px solid #eee; */
    background: url(/static/images/202228.png);
    position: absolute;
    top: 40px;
    right: 0px;
  }
  .patentTransfer-box-two >span:nth-child(5) {
    position: absolute;
    top: 50px;
    right: -330px;
    font-family: "PingFangSC";
    font-size: 16px;
    font-weight: 300;
     color:#EB5E00;
  }
  /* 申报表A类&&申报表A类封面 */
  .patentTransfer-box-declareA > span:nth-child(1) {
    display: block;
    font-family: "PingFangSC";
    font-size: 18px;
    color: #333;
    margin-bottom: 16px;
  }
  .patentTransfer-box-declareA > div:nth-child(2) {
    display: inline-block;
  }
  .dataimg{
     width: 132px;
     height: 120px;
 }
  .patentTransfer-box-declareA > span:nth-child(3) {
    font-family: "PingFangSC";
    font-size: 16px;
    font-weight: 300;
    color: #EB5E00;
  }
  /* 取消保存 */
  .patentTransfer-box-delSub {
    text-align: center;
    margin-top: 68px;
    margin-bottom: 80px;
  }
  .patentTransfer-box-delSub > button {
    width: 80px;
    height: 36px;
    line-height: 36px;
    display: inline-block;
    text-align: center;
    font-family: "PingFangSC";
    font-size: 14px;
    cursor: pointer;
    margin: 0;
    padding: 0;
    border: 1px solid transparent;
    outline: none;
  }
  .patentTransfer-box-delSub > button:nth-child(1) {
    background-color: #efefef;
    border-radius: 4px;
    color: #f87e03;
    margin-right: 16px;
  }
  .patentTransfer-box-delSub > button:nth-child(2) {
    background-color: #EB5E00;
    border-radius: 4px;
    color: #fff;
  }
  /* 提示 */
  .form-msg {
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    color: red;
  }
  /* 备注 */
  .remarks {
    font-family: "PingFangSC";
    font-size: 14px;
    color: #EB5E00;
    font-weight: 300;
    margin-bottom: 20px;
    /* float: right; */
  }
  .remarks > img {
    vertical-align: middle;
  }
  .remarks > span {
    vertical-align: middle;
    /* border-bottom: 1px solid #EB5E00; */
  }
  .remarks1 {
    font-family: "PingFangSC";
    font-size: 14px;
    color: #4a90e2;
    font-weight: 300;
    margin-bottom: 20px;
    /* float: right; */
  }
  .remarks1 > img {
    vertical-align: middle;
    /* margin-right: 10px; */
  }
  .remarks1 > a {
    vertical-align: middle;
    color: #4a90e2;
    border-bottom: 1px solid #4a90e2;
    cursor: pointer;
    text-decoration: none;
  }
  input[disabled] {
    background-color: #fff;
  }
  .mb26{
    margin-bottom: 26px;
  }
  /* 减缓材料 */
  .posab {
    position: absolute;
    top:72px;
    right: -286px;
    color: #000 !important;
    font-family: "PingFangSC";
  }
  .posab > p:nth-child(2) {
    margin-left: 18px;
  }
  .posab > p:nth-child(5) {
    /* margin-left: 18px; */
    margin-top: 10px;
  }
  .posab > p:nth-child(6) {
    margin-left: 18px;
    /* margin-top: 20px; */
  }
  .fail{
     margin-top: 20px;
     font-size: 20px;
     color: red;
    margin-bottom: 26px;
  }
</style>
